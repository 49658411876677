import React, { useEffect, useState } from 'react';
import useAxiosRequest, { GET } from "../../hooks/useAxiosRequest";
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@mui/material';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const renderTable = (data, columns, context, onClickHandler, title) => (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant="h6" align="center">
            {title}
        </Typography>
        <List dense={true}>
            {data.map((item, index) => (
                <ListItem
                    button
                    key={index}
                    onClick={() => onClickHandler(item.id, context)}
                    sx={{
                        cursor: 'pointer',
                        border: '1px solid #e0e0e0',
                        borderBottom: '2px solid #e0e0e0',
                        padding: '0px 5px 0px 5px ',
                        marginBottom: '10px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                        transition: 'background-color 0.3s, box-shadow 0.3s',
                    }}
                >
                    <Grid container alignItems="center">
                        {columns.map((col, index) => (
                            <Grid item xs={col.width} key={index}>
                                <ListItemText
                                    primary={item[col.key]}
                                    secondary={col.secondary ? item[col.secondary] : null}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </ListItem>
            ))}
        </List>
    </Paper>
);

const InvoiceComponent = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [context, setContext] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const selectedBranch = useSelector((state) => state.nav.selectedBranch);
    const user = useSelector((state) => state.auth.user);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [downloadPdf, setDownloadPdf] = useState(false);
    const axiosRequest = useAxiosRequest();

    useEffect(() => {
        setLoading(true);
        const [request, cancelRequest] = axiosRequest(GET, `/driver-api/invoices/invoices`, {
            'driver_id': user.id,
            'branch_code': selectedBranch.code
        });

        request.then(response => {
            setData(response);
        }).catch(error => {
            // Handle error if needed
        }).finally(() => {
            setLoading(false);
        });

        return () => {
            cancelRequest();
        };
    }, [user, selectedBranch, axiosRequest]);

    const handleInvoiceSelected = (id, thisContext) => {
        setLoading(true);
        setContext(thisContext);

        let dataRecordWithId;
        if (thisContext === 'invoice')
            dataRecordWithId = data.driverInvoices.find((record) => record.id === id);
        if (thisContext === 'credit')
            dataRecordWithId = data.driverCredits.find((record) => record.id === id);
        if (thisContext === 'payment')
            dataRecordWithId = data.driverPayments.find((record) => record.id === id);

        setSelectedInvoice(dataRecordWithId);

        const [request, cancelRequest] = axiosRequest(GET, `/driver-api/invoices/invoice-detail`, {
            'id': id,
            'type': thisContext
        });

        request.then(response => {
            setInvoiceDetails(response);
            setOpenDialog(true);
        }).catch(error => {
            // Handle error if needed
        }).finally(() => {
            setLoading(false);
        });

        return () => {
            cancelRequest();
        };
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setInvoiceDetails(null);
    };

    const invoiceColumns = [
        { label: 'Date', key: 'invoice_number', width: 10, secondary: 'date' },
        { label: 'Total', key: 'total', width: 2, align: 'right' },
    ];

    const creditColumns = [
        { label: 'Date', key: 'receipt_number', width: 10, secondary: 'date' },
        { label: 'Total', key: 'total', width: 2, align: 'right' },
    ];

    const paymentColumns = [
        { label: 'Date', key: 'reference_number', width: 10, secondary: 'date' },
        { label: 'Total', key: 'total', width: 2, align: 'right' },
    ];

    const capitalizeFirstLetter = (str) => {
        if (!str)
            return '';
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    return (
        <Box sx={{ padding: 2 }}>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {data && (
                <>
                    {renderTable(data.driverInvoices, invoiceColumns, 'invoice', handleInvoiceSelected, 'Your Debits')}
                    {renderTable(data.driverCredits, creditColumns, 'credit', handleInvoiceSelected, 'Your Credits')}
                    {renderTable(data.driverPayments, paymentColumns, 'payment', handleInvoiceSelected, 'Your Payments')}
                </>
            )}

            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle sx={{ textAlign: 'center', position: 'relative' }}>
                    {capitalizeFirstLetter(context)}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {invoiceDetails && (
                        <>
                            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{
                                                fontWeight: 'bold',
                                                padding: '0 4px 0 4px'
                                            }}>From:</TableCell>
                                            <TableCell sx={{
                                                padding: '0 4px 0 4px'
                                            }}>{selectedInvoice.from_name}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{paddingBottom: '10px'}}>
                                            <TableCell sx={{
                                                fontWeight: 'bold',
                                                padding: '0 4px 0 4px'
                                            }}>Address:</TableCell>
                                            <TableCell sx={{
                                                padding: '0 4px 0 4px'
                                            }}>{selectedInvoice.from_address}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{
                                            padding: '10px'
                                        }}>&nbsp;</TableRow>
                                        <TableRow>
                                            <TableCell sx={{
                                                fontWeight: 'bold',
                                                padding: '0 4px 0 4px'
                                            }}>Date:</TableCell>
                                            <TableCell sx={{
                                                padding: '0 4px 0 4px'
                                            }}>{selectedInvoice.date}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{paddingBottom: '10px'}}>
                                            <TableCell sx={{
                                                fontWeight: 'bold',
                                                padding: '0 4px 0 4px',
                                            }}>Invoice Number:</TableCell>
                                            <TableCell sx={{
                                                padding: '0 4px 0 4px'
                                            }}>{selectedInvoice.invoice_number ?? selectedInvoice.reference_number ?? selectedInvoice.receipt_number}</TableCell>
                                        </TableRow>
                                        {selectedInvoice.bank_reference &&
                                            <TableRow sx={{paddingBottom: '10px'}}>
                                                <TableCell sx={{
                                                    fontWeight: 'bold',
                                                    padding: '0 4px 0 4px',
                                                }}>Bank Reference:</TableCell>
                                                <TableCell sx={{
                                                    padding: '0 4px 0 4px'
                                                }}>{selectedInvoice.bank_reference}</TableCell>
                                            </TableRow>
                                        }
                                        <TableRow sx={{
                                            padding: '10px'
                                        }}>&nbsp;</TableRow>
                                        <TableRow>
                                            <TableCell sx={{
                                                fontWeight: 'bold',
                                                padding: '0 4px 0 4px'
                                            }}>To:</TableCell>
                                            <TableCell sx={{
                                                padding: '0 4px 0 4px'
                                            }}>{selectedInvoice.to_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{
                                                fontWeight: 'bold',
                                                padding: '0 4px 0 4px'
                                            }}>Address:</TableCell>
                                            <TableCell sx={{
                                                padding: '0 4px 0 4px'
                                            }}>{selectedInvoice.to_address}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', padding: '0 4px 0 4px', width: "70%" }}>Item</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', padding: '0 4px 0 4px', width: "10%" }}>Qty</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', padding: '0 4px 0 4px', textAlign: 'right', width: "20%" }}>Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {context === 'invoice' && invoiceDetails.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                    <ListItemText
                                                        primary={item.item}
                                                        secondary={item.description}
                                                        sx={{ padding: '0 4px 0 4px' }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ padding: '0 4px 0 4px' }}>{item.quantity}</TableCell>
                                                <TableCell sx={{ padding: '0 4px 0 4px', textAlign: 'right' }}>{item.period_cost_vat_inc}</TableCell>
                                            </TableRow>
                                        ))}
                                        {context === 'credit' && invoiceDetails.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                    <ListItemText
                                                        primary={item.item}
                                                        secondary={item.description}
                                                        sx={{ padding: '0 4px 0 4px' }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ padding: '0 4px 0 4px' }}>{item.quantity}</TableCell>
                                                <TableCell sx={{ padding: '0 4px 0 4px', textAlign: 'right' }}>{item.total}</TableCell>
                                            </TableRow>
                                        ))}
                                        {context === 'payment' && invoiceDetails.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                    <ListItemText
                                                        primary={item.item}
                                                        secondary={item.description}
                                                        sx={{ padding: '0 4px 0 4px' }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ padding: '0 4px 0 4px' }}>{item.quantity}</TableCell>
                                                <TableCell sx={{ padding: '0 4px 0 4px', textAlign: 'right' }}>{item.total}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow sx={{ paddingTop: '10px' }}>
                                            <TableCell sx={{ padding: '6px' }}></TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '6px' }}>Total</TableCell>
                                            <TableCell sx={{ padding: '0 4px 0 4px', textAlign: 'right', fontWeight: 'bold', }}>{selectedInvoice.total}</TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default InvoiceComponent;
