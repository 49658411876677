import React, {useEffect} from 'react';
import {green, orange, red} from "@mui/material/colors";
import {FiberManualRecord} from "@mui/icons-material";
import {getEchoInstance} from "./pusherEchoInstance";
import {useSelector} from "react-redux";

const ConnectionIndicator = ( {isConnected} ) => {
    const branchCode = useSelector(state => state.auth.selectedBranch);

    useEffect(() => {
        const echo = getEchoInstance();

        if (!branchCode) return;

        const presenceChannel = `presence.${branchCode}`;

        echo.join(presenceChannel);
            /*.here((currentUsers) => {
                setUsers(currentUsers);
            })
            .joining((joiningUser) => {
                setUsers(prevUsers => [...prevUsers, joiningUser]);
            })
            .leaving((leavingUser) => {
                setUsers(prevUsers => prevUsers.filter(u => u.id !== leavingUser.id));
            });*/

        return () => {
            echo.leave(presenceChannel);
        };
    }, [branchCode]);

    const color = isConnected.value === 'connected' ? green[500] : (isConnected.value === 'connecting' ? orange[500] : red[500]);

    return (
        <FiberManualRecord style={{ color: color }} />
    );
};

export default ConnectionIndicator;
