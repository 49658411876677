import React, {useState, useEffect} from 'react';
import {Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import AuthIcon from '@mui/icons-material/VerifiedUser';
import axios from "../../../store/tokenAxios/tokenAxios";
import {useDispatch, useSelector} from "react-redux";
import {PAGE_LAYOUT, setPage} from "../../../store/slices/navSlice";
import CenteredPaperBox from "../CenteredPaperBox";
import Helpers from "../../../components/Helpers";
import {useNavigate} from "react-router-dom";
import {setUserAndBranch} from "../../../store/slices/setUserAndBranch";

function Authenticator({initData, params}) {
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    //const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        const token = localStorage.getItem('dam_driver_token');
        const tokenObject = token ? JSON.parse(token) : null;

        if( token?.token !== null && user){
            dispatch(setPage(PAGE_LAYOUT));
            return;
        }
        axios.post('/driver-api/telegram/login', {
            telegram_id: initData.user.id,
            telegram_user: initData.user.firstName + ' ' + initData.user.lastName,
        }).then((response) => {
            response = response?.response ? response.response : response;
            if (response.status < 200 || response.status >= 300) {
                const errorMessage = Helpers.parseError(response);
                setError(errorMessage);
                return;
            }
            dispatch(setUserAndBranch(response.data));
            //navigate("/");

            if( params?.page)
                dispatch(setPage(params));
            else
                dispatch(setPage(PAGE_LAYOUT));

        }).catch((e) => {
            if(e.response && e.response.status === 401) {
                setErrorCode(e.response.status);
                const errorMessage = Helpers.parseError(e);
                setError(errorMessage ? errorMessage : JSON.stringify(e.response, null, 2));
                //setError("This telegram ID is not registered");
            } else {
                const errorMessage = Helpers.parseError(e);
                setError(errorMessage ? errorMessage : JSON.stringify(e, null, 2));
            }
            setLoading(false);
        });
    }, [initData]);

    if (error) {
        return (
            <CenteredPaperBox>
                <ErrorIcon color="error" style={{fontSize: 60}}/>
                <Typography variant="h5" color="error">Authentication Error</Typography>
                <Typography>{error}</Typography>
                { errorCode === 401 &&
                    <Typography>Contact your branch to receive a registration link to register your telegram ID for self-service</Typography>
                }
            </CenteredPaperBox>
        );
    }

    if (loading)
        return (
            <CenteredPaperBox>
                <AuthIcon style={{fontSize: 60}}/>
                <Typography variant="h5">Authenticating</Typography>
            </CenteredPaperBox>
        );

    return (
        <CenteredPaperBox>
            <Typography variant="h5">Unexpected error E02</Typography>
        </CenteredPaperBox>
    )
}

export default Authenticator;
