import {useDispatch, useSelector} from "react-redux";
import {Button, Grid, Stack, styled, Typography} from "@mui/material";
import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import useAxiosRequest from "../hooks/useAxiosRequest";
import LoadingSpinner from "../hoc/LoadingSpinner";
import DriverStatus from "./DriverStatus";
import {PAGE_ON_DUTY, setPage} from "../store/slices/navSlice";
import BadgeIcon from '@mui/icons-material/Badge'
import {backgroundColorSignal, GREEN, ORANGE} from "../containers/base/pages/Layout";
import ComplianceStatus from "./ComplianceStatus";
import {useMessageContext} from "./messageContext/MessageContext";
import {getEchoInstance} from "../services/pusherEchoInstance";
import {enqueueSnackbar} from "notistack";

const LargeIcon = styled(BadgeIcon)({
    fontSize: '2em !important',
});

function TodaysShifts() {
    const selectedBranch = useSelector((state) => state.nav.selectedBranch);
    const user = useSelector((state) => state.auth.user);
    const axiosRequest = useAxiosRequest();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const complianceRef = React.createRef();
    const dispatch = useDispatch();
    const [autoRefresh, setAutoRefresh] = useState(() => Date.now());
    const {hydrateMessages} = useMessageContext();

    useEffect(() => {
        if (user?.id === null) return;

        const echo = getEchoInstance();
        const channel = `driver.${user.id}.device`;

        console.log('driver messages', channel);

        echo.private(channel)
            .listen('DriverDeviceEvent', (e) => {
                console.log('driver device message', e);
                setAutoRefresh( Date.now() );
            }).error((e) => {
            echo.retryAuthentication();
        });

        return () => {
            echo.leave(channel);
        }
        //hydrateMessages();
    }, [user, selectedBranch]);

    useLayoutEffect(() => {
        if (user === null) return;
        if (!selectedBranch || !selectedBranch?.code) return;

        console.log('selectedBranch', selectedBranch);

        setLoading(true);

        const [request, cancelRequest] =
            axiosRequest('get', `/driver-api/operations/driver-shift-status`, {
                'branch_code': selectedBranch.code
            });

        request.then(response => {
            console.log(response)
            setData(response);
            hydrateMessages();
        }).catch(error => {
        }).finally(() => {
            setLoading(false);
        });

        return () => {
            cancelRequest();
        };
    }, [user, selectedBranch, autoRefresh]);

    useLayoutEffect(() => {
        backgroundColorSignal.value = data !== null && data.self_shifted ? GREEN : ORANGE;
    }, [data]);

    function handleOnDutyClick() {
        dispatch(setPage(PAGE_ON_DUTY));
    }

    const [allowSelfShift, uniformAuditRequired] = useMemo(() => {
        if (!data || !selectedBranch) return [false, false];

        const isSatellite = (selectedBranch.id !== selectedBranch.main_branch_id) && !selectedBranch.treat_as_main;
        const shifted = data.status.startsWith('shifted');  // shifted or shifted_late
        const uniformAuditRequired = !!data.uniform_audit_required && shifted;
        const uniformAuditPassed = !!data.uniform_audit_passed;

        let allowSelfShift = shifted && (isSatellite || uniformAuditPassed);
        //allowSelfShift = allowSelfShift || !!user.adhoc_shifting;

        return [allowSelfShift, uniformAuditRequired];
    }, [data, selectedBranch, user]);


    const onDutyStatus = user.adhoc_shifting ? 'Ad-Hoc driver' : 'You are not on duty';
    const compliant = (data?.compliance?.compliance_state ?? 'valid') !== 'valid';
    const complianceWarning =  compliant ?
        <Button sx={{margin: '0 20px 0 20px'}} variant={'contained'} onClick={() => complianceRef.current.openDialog()}>Doc Compliance Warning</Button> : <></>;

    return (
        <LoadingSpinner flag={loading}>
            {!!data &&
                <Grid container spacing={2}>
                    {data.self_shifted ?
                        <Grid item xs={12} md={10} alignSelf={"center"} sx={{textAlign: "center"}}>
                            <Typography variant="h6">Today's Shifts</Typography>
                            <Typography variant="h5">{data.pretty}</Typography>
                            {complianceWarning}
                        </Grid>
                        :
                        <Grid item xs={12} md={10} alignSelf={"center"} sx={{textAlign: "center"}}>
                            <Typography variant="h6">{onDutyStatus}</Typography>
                            {(allowSelfShift && !data.self_shifted) ?
                                <>
                                    { data.compliance.can_shift &&
                                        <Button
                                            variant="contained"
                                            startIcon={<LargeIcon/>}
                                            onClick={handleOnDutyClick}
                                        >
                                            Place yourself on duty
                                        </Button>
                                    }
                                    {complianceWarning}
                                </>
                                :
                                <Stack>
                                    <Typography variant="h5">{data.pretty}</Typography>
                                    {uniformAuditRequired && data.compliance.can_shift &&
                                        <Typography color={"secondary"} variant="h5">Please visit the office for your
                                            uniform audit</Typography>
                                    }
                                    {complianceWarning}
                                </Stack>
                            }
                        </Grid>
                    }
                    <Grid item xs={12} md={2} alignSelf={"center"} sx={{textAlign: "center"}}>
                        <Stack direction={'column'} spacing={2}>
                            <DriverStatus status={data.status}/>
                            <ComplianceStatus ref={complianceRef} status={data.compliance}/>
                        </Stack>
                    </Grid>
                </Grid>
            }
        </LoadingSpinner>
    );
}

export default TodaysShifts;