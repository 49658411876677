import React from 'react';
import { Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ScheduleIcon from '@mui/icons-material/Schedule';

export const DRIVER_STATUS_SHIFTED = 'shifted';

export const DRIVER_STATUS_NOT_SHIFTED = 'not_shifted';
export const DRIVER_STATUS_ON_DUTY = 'on_duty';
export const DRIVER_STATUS_SUSPENDED = 'suspended';
export const DRIVER_STATUS_SHIFTED_LATE = 'shifted_late';

function DriverStatus({ status }) {
    const statusAttributes = {
        not_shifted: {
            label: 'Not Shifted',
            color: 'warning',
            icon: <CheckCircleIcon />,
        },
        shifted: {
            label: 'Shifted',
            color: 'success',
            icon: <CheckCircleIcon />,
        },
        on_duty: {
            label: 'On Duty',
            color: 'primary',
            icon: <AccessTimeIcon />,
        },
        suspended: {
            label: 'Suspended',
            color: 'error',
            icon: <HighlightOffIcon />,
        },
        shifted_late: {
            label: 'Shifted Late',
            color: 'warning',
            icon: <ScheduleIcon />,
        }
    };

    const { label, color, icon } = statusAttributes[status] || {};

    return (
        <Chip
            sx={{
                backgroundColor: "white",
            }}
            icon={icon}
            label={label}
            color={color}
            variant="outlined"
        />
    );
}

export default DriverStatus;