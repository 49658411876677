import { createSlice } from '@reduxjs/toolkit';
import {MENU_HOME} from "../../containers/base/pages/Layout";

export const PAGE_LOADING = 'loading';
export const PAGE_AUTH = 'auth';
export const PAGE_CONFIRM = 'confirm';
export const PAGE_ON_DUTY = 'on_duty';
export const PAGE_LAYOUT = 'layout';

const navSlice = createSlice({
    name: 'nav',
    initialState: {
        page: "loading",
        error: "",
        loading: false,
        menuPage: MENU_HOME,
        selectedBranch: null,
    },
    reducers: {
        setPage(state, action) {
            //console.log("Setting page to: " + action.payload)
            state.page = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setMenuPage(state, action) {
            state.menuPage = action.payload
        },
        setSelectedBranch(state, action) {
            state.selectedBranch = action.payload
        }
    },
});

export const { setMenuPage, setPage, setError, setLoading, setSelectedBranch} = navSlice.actions
export default navSlice.reducer;
