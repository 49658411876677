import React, {useState, useEffect} from 'react';
import {CircularProgress, Box, Typography, Paper, Button} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import axios from "../../../store/tokenAxios/tokenAxios";
import {useDispatch} from "react-redux";
import {PAGE_AUTH, setPage} from "../../../store/slices/navSlice";
import CenteredPaperBox from "../CenteredPaperBox";
import Helpers from "../../../components/Helpers";


function Confirm({initData, params}) {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();

    function doCallback() {
        axios.post('/driver-api/telegram/deeplink-callback', {
            token: params[0],
            telegram_id: initData.user.id,
            telegram_user: initData.user.firstName + ' ' + initData.user.lastName,
        }).then((response) => {
            if (response.status !== 204) {
                setError(response.data);
                return;
            }
            setSuccess(true);
        }).catch((e) => {
            const errorMessage = Helpers.parseError(e);
            setError(errorMessage);
        });
    }

    function done() {
        dispatch(setPage(PAGE_AUTH));
    }

    if (error) {
        // If there's an error, show an error message
        return (
            <CenteredPaperBox>
                <ErrorIcon color="error" style={{fontSize: 60}}/>
                <Typography variant="h5" color="error">Authentication Error</Typography>
                <Typography>{error}</Typography>
            </CenteredPaperBox>
        );
    } else if (success) {
        return (
            <CenteredPaperBox>
                <SuccessIcon style={{fontSize: 60}}/>
                <Typography variant="h5">Authenticated Successfully!</Typography>
                <Typography>Click Done to continue</Typography>
                <Button variant="contained" color="primary" onClick={done}>Done</Button>
            </CenteredPaperBox>
        );
    }

    return (
        <CenteredPaperBox>
            <SuccessIcon style={{fontSize: 60}}/>
            <Typography variant="h5">Telegram Identification</Typography>
            <Typography>Click continue to associate your TDT driver profile with this Telegram account to enable
                self-service functionality</Typography>
            <Button variant="contained" color="primary" onClick={doCallback}>Continue</Button>
        </CenteredPaperBox>
    )
}

export default Confirm;
