import {TextField} from "@mui/material";
import React from "react";

const MaskTextField = ({onChange, inputMask= /./, uppercase = false, ...props}) => {
    const handleChange = (e) => {
        const newValue = uppercase?e.target.value.toUpperCase():e.target.value;
        // Filter out any characters that don't match the inputMask
        const filteredValue = Array.from(newValue)
            .filter(char => inputMask.test(char))
            .join('');

        if (onChange) {
            onChange( filteredValue);
        }
    };

    return (
        <TextField
            {...props}
            onChange={handleChange}
            type="text" // Use 'text' type to allow custom formatting
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            InputProps={{
                size: 'small'
            }}
        />
    );
};

export default MaskTextField;