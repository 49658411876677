import React, {createContext, useContext, useEffect, useState} from 'react';
import {getEchoInstance} from "../../services/pusherEchoInstance";
import {useSelector} from "react-redux";
import useSoundPlayer from "../../hooks/useSoundPlayer";
import {enqueueSnackbar} from "notistack";
import useAxiosRequest, {GET,DELETE} from "../../hooks/useAxiosRequest";

const MessageContext = createContext();

export const useMessageContext = () => useContext(MessageContext);

export const MessageProvider = ({children}) => {
    const [messages, setMessages] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const {playNewNotificationSound} = useSoundPlayer();
    const axiosRequest = useAxiosRequest();

    const hydrateMessages = () => {
        console.log('fetching messages', user, );

        const [request, cancelRequest] =
            axiosRequest(GET, `/driver-api/notifications/list`, {
                'driver_id': user.id,
            });

        request.then(response => {
            setMessages(response);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        if( !user?.id ) return;

        const echo = getEchoInstance();
        const channel = `driver.${user.id}.message`;

        console.log('driver messages', channel);

        echo.private(channel)
            .listen('DriverMessageEvent', (e) => {
                console.log('driver message', e);
                setMessages((prevMessages) => {
                    const index = prevMessages.findIndex((message) => message.id === e.id);
                    if (index !== -1) {
                        prevMessages[index] = e;
                        return [...prevMessages];
                    } else {
                        playNewNotificationSound();
                        enqueueSnackbar(e.message, {variant: 'info'});
                        return [...prevMessages, e];
                    }
                });
            }).error((e) => {
                console.log('message error', e);
                echo.retryAuthentication();
            });

        return () => {
            echo.leave(channel);
        }
    }, []);

    function deleteMessage(id) {
        setMessages(messages.filter(message => message.id !== id));

        axiosRequest(DELETE, `/driver-api/notifications/delete/${id}`,{});
    }

    return (
        <MessageContext.Provider value={{messages, hydrateMessages, deleteMessage}}>
            {children}
        </MessageContext.Provider>
    );
};
