import {Fragment, useEffect, useState} from "react";
import {
    Button,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import useAxiosRequest, {GET} from "../../hooks/useAxiosRequest";
import {useSelector} from "react-redux";
import InvoiceSharedBody from "../shared/invoice/InvoiceSharedBody";

const emptyObject = {};

const InvoiceView = ({data, version = 'new'}) => {
    const [open, setOpen] = useState(emptyObject);

    const handleToggle = (key) => {
        setOpen((prev) => ({...prev, [key]: !prev[key]}));
    };

    return (
        <Paper elevation={2} sx={{
            padding: '20px',
            marginTop: '20px',
            backgroundColor: version === 'old' ? 'yellow' : 'white'
        }}>
            <InvoiceSharedBody data={data} handleToggle={handleToggle} open={open}/>
        </Paper>
    );
}

function Invoice() {
    const [invoices, setInvoices] = useState([]);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.auth.user);
    const selectedBranch = useSelector((state) => state.nav.selectedBranch);
    const axiosRequest = useAxiosRequest();

    useEffect(() => {
        setLoading(true);

        const [request, cancelRequest] =
            axiosRequest(GET, `/driver-api/operations/invoices`, {
                'driver_id': user.id,
                'branch_code': selectedBranch.code,
            });

        request.then(response => {
            //console.log(response)
            setInvoices(response);
        }).catch(error => {
            // Handle error if needed
        }).finally(() => {
            setLoading(false);
        });

        return () => {
            cancelRequest();
        };
    }, [user, selectedBranch, axiosRequest]);

    function getInvoice(id) {
        const [request, cancelRequest] =
            axiosRequest(GET, `/driver-api/operations/invoice`, {
                'id': id
            });

        request.then(response => {
            //console.log(response)
            setData(response);
        }).catch(error => {
            // Handle error if needed
        }).finally(() => {

        });

        return () => {
            cancelRequest();
        };
    }

    console.log('rendering invoice', data);

    return (
        loading ? <Stack gap={2}>
                <Typography>
                    Loading Invoices
                </Typography>
            </Stack>
            :
            <Fragment>
                {invoices.length === 0 ?
                    <Stack gap={2}>
                        <Typography>
                            No Invoices Available
                        </Typography>
                    </Stack>
                    :
                    invoices.map((invoice) =>
                        <Button variant={"outlined"} color={"primary"} key={`bk_${invoice.id}`}
                                onClick={() => getInvoice(invoice.id)}>{invoice.invoice_number}</Button>
                    )
                }

                {data !== null && data !== false &&
                    <InvoiceView data={data.final}/>
                }

            </Fragment>
    );
}

export default Invoice;
