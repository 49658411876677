const newMessageSound = require('../assets/sound/new-message.mp3');
const newNotificationSound = require('../assets/sound/mixkit-positive-notification-951.mp3');


const useSoundPlayer = () => {
    const playSound = (soundFile) => {
        const newAudio = new Audio(soundFile);
        newAudio.play().catch(error => console.error('Error playing sound:', error));
    };
    const playNewMessageSound = () => playSound(newMessageSound);
    const playNewNotificationSound = () => playSound(newNotificationSound);

    return {
        playNewMessageSound,
        playNewNotificationSound,
    };
};

export default useSoundPlayer;
