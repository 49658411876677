import { configureStore } from '@reduxjs/toolkit';
import navSlice from "./slices/navSlice";
import authSlice from "./slices/authSlice";

const rootReducer = {
    auth: authSlice,
    nav: navSlice,
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;
