import {useSelector} from "react-redux";
import {CircularProgress, Box} from "@mui/material";
import React, {Fragment, useState} from "react";

function LoadingSpinner({children, flag}) {

    if (flag) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress color="inherit"/>
            </Box>
        );
    }

    return <Fragment>
        {children}
    </Fragment>
}

export default LoadingSpinner;
