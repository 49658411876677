import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../hoc/LoadingSpinner";
import useAxiosRequest from "../../hooks/useAxiosRequest";
import MaskTextField from "../form/MaskTextField";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const FormField = ({ name, label, inputMask, uppercase }) => (
    <Box sx={{ mb: 2 }}>
        <Field name={name}>
            {({ field, meta }) => (
                <MaskTextField
                    {...field}
                    inputMask={inputMask}
                    uppercase={uppercase}
                    label={label}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                />
            )}
        </Field>
    </Box>
);

function BankDetails() {
    const user = useSelector((state) => state.auth.user);
    const [bankData, setBankData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const axiosRequest = useAxiosRequest();

    useEffect(() => {
        if (user === null) return;

        setLoading(true);

        const [request, cancelRequest] = axiosRequest(
            "get",
            `/driver-api/operations/bank-details`,
            {
                driver_id: user.id,
            }
        );

        request
            .then((response) => {
                setBankData(response);
            })
            .catch((error) => {
                // Handle error if needed
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            cancelRequest();
        };
    }, [user]);

    const validationSchema = Yup.object().shape({
        account_name: Yup.string().required("Account Name is required"),
        branch_code: Yup.string()
            .matches(/^\d+$/, "Branch Code must be numeric")
            .required("Branch Code is required"),
        account_number: Yup.string()
            .matches(/^\d+$/, "Account Number must be numeric")
            .required("Account Number is required"),
        address1: Yup.string().required("Address Line 1 is required"),
        address2: Yup.string(),
        suburb: Yup.string().required("Suburb is required"),
        town: Yup.string().required("Town is required"),
        postcode: Yup.string()
            .matches(/^\d+$/, "Post Code must be numeric")
            .required("Post Code is required"),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        // Handle form submission here
        // For example, send the data to the server
        console.log("Form Submitted:", values);

        // After submission, setSubmitting(false);
        setSubmitting(false);

        // Navigate to home or show a success message
        navigate("/");
    };

    return (
        <LoadingSpinner flag={loading}>
            <Box sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Please make sure your details are correct to ensure timely payment
                </Typography>
                {bankData !== null && (
                    <Formik
                        initialValues={{
                            account_name: bankData.account_name || "",
                            branch_code: bankData.branch_code || "",
                            account_number: bankData.account_number || "",
                            address1: bankData.address1 || "",
                            address2: bankData.address2 || "",
                            suburb: bankData.suburb || "",
                            town: bankData.town || "",
                            postcode: bankData.postcode || "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ isSubmitting, dirty, isValid }) => (
                            <Form>
                                <Typography variant="h6" gutterBottom>
                                    Bank Account for Payments
                                </Typography>
                                <FormField
                                    name="account_name"
                                    label="Account Name"
                                    inputMask={/[\sA-Za-z0-9-]/}
                                />
                                <FormField
                                    name="branch_code"
                                    label="Branch Code"
                                    inputMask={/[0-9]/}
                                />
                                <FormField
                                    name="account_number"
                                    label="Account Number"
                                    inputMask={/[0-9]/}
                                />
                                <Typography variant="h6" gutterBottom>
                                    Address Details for Invoices
                                </Typography>
                                <FormField name="address1" label="Address Line 1" />
                                <FormField name="address2" label="Address Line 2" />
                                <FormField name="suburb" label="Suburb" />
                                <FormField name="town" label="Town" />
                                <FormField
                                    name="postcode"
                                    label="Post Code"
                                    inputMask={/[0-9]/}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={isSubmitting || !(dirty && isValid)}
                                    sx={{ mb: 2 }}
                                >
                                    Save
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => navigate("/")}
                                >
                                    Close
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
        </LoadingSpinner>
    );
}

export default BankDetails;
