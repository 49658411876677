import React, {useState} from 'react';
import {
    Typography,
    Button,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Box,
    FormControlLabel,
    Radio, RadioGroup, Divider
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import axios from "../../../store/tokenAxios/tokenAxios";
import {useDispatch, useSelector} from "react-redux";
import {PAGE_AUTH, PAGE_LAYOUT, setPage, setSelectedBranch} from "../../../store/slices/navSlice";
import CenteredPaperBox from "../CenteredPaperBox";
import Helpers from "../../../components/Helpers";


function OnDuty({initData, params}) {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [deliveryType, setDeliveryType] = useState('');
    const selectedBranch = useSelector((state) => state.nav.selectedBranch);
    const user = useSelector((state) => state.auth.user);
    const [submitting, setSubmitting] = useState(false);

    function doCallback() {
        setSubmitting(true);
        axios.post(`/driver-api/telegram/on-duty`, {
            branch_code: selectedBranch.code,
            vehicle_type: selectedVehicle,
            driver_id: user.id,
            shift_type: deliveryType,
            self_shifted: true,
            context: 'app'
        }).then((response) => {
            setSubmitting(false);
            response = response?.response ? response.response : response;
            if (response.status < 200 || response.status >= 300) {
                const errorMessage = Helpers.parseError(response);
                setError(errorMessage);
                return;
            }
            setSuccess(true);
        }).catch((e) => {
            const errorMessage = Helpers.parseError(e);
            setError(errorMessage);
            setSubmitting(false);
        });
    }

    function done() {
        dispatch(setPage(PAGE_AUTH));
    }

    function handleChange(event) {
        setSelectedVehicle(event.target.value);
    }

    const handleDeliveryTypeChange = (event) => {
        setDeliveryType(event.target.value);
    };

    if (error) {
        // If there's an error, show an error message
        return (
            <CenteredPaperBox>
                <ErrorIcon color="error" style={{fontSize: 60}}/>
                <Typography variant="h5" color="error">Error</Typography>
                <Typography>{error}</Typography>
            </CenteredPaperBox>
        );
    } else if (success) {
        return (
            <CenteredPaperBox>
                <SuccessIcon style={{color: "green", fontSize: 60}}/>
                <Typography variant="h5">Great, you are now on duty</Typography>
                <Box>
                    <Typography variant="h5">Remember to wear your approved uniform while on duty</Typography>
                    <Typography variant="h5">Be safe and have a great shift</Typography>
                </Box>
                <Typography>Click Done to continue</Typography>
                <Button variant="contained" color="primary" onClick={done}>Done</Button>
            </CenteredPaperBox>
        );
    }

    const types = [
        {code: 'bakkie', name: 'Bakkie'},
        {code: 'hatchback', name: 'Hatchback'},
        {code: 'motorbike', name: 'Motorbike'},
        {code: 'sedan', name: 'Sedan'},
        {code: 'small_truck', name: 'Small Truck'},
        {code: 'suv', name: 'SUV'},
    ];

    const services = [];
    if( !!user.on_demand || ( !user.express && !user.on_demand ))
        services.push(<FormControlLabel key={"od"} value="on_demand" control={<Radio />} label="On Demand" />);
    if( !!user.express)
        services.push(<FormControlLabel key={"ex"} value="express" control={<Radio />} label="Express" />);

    return (
        <CenteredPaperBox>
            <Typography variant="h5">Place yourself on duty</Typography>
            <FormControl fullWidth>
                <InputLabel id="branch-select-label">Vehicle Type</InputLabel>
                <Select
                    labelId="branch-select-label"
                    value={selectedVehicle}
                    label="Branch"
                    onChange={handleChange}
                >
                    {types.map((vt =>
                            <MenuItem key={vt.code} value={vt.code}>
                                {vt.name}
                            </MenuItem>
                    ))}
                </Select>

                <Divider></Divider>

                <RadioGroup
                    aria-label="delivery type"
                    name="deliveryType"
                    value={deliveryType}
                    onChange={handleDeliveryTypeChange}
                >
                    {services.map((service) => service)}
                </RadioGroup>
            </FormControl>
            <Typography>Please select where you will be working today and which vehicle you will be driving</Typography>
            <Button disabled={ submitting || selectedVehicle === '' || deliveryType === ''} variant="contained" color="primary" onClick={doCallback}>Go on
                duty</Button>
        </CenteredPaperBox>
    )
}

export default OnDuty;
