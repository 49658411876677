import {useDispatch, useSelector} from "react-redux";
import {setSelectedBranch} from "../store/slices/navSlice";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

function BranchSelector() {
    const selectedBranch = useSelector((state) => state.nav.selectedBranch);
    const user = useSelector((state) => state.auth.user);
    const branches = useSelector((state) => state.auth.branches);
    const dispatch = useDispatch();

    function onChange(event) {
        // find branch with selected code
        const selectedBranch = branches.find(branch => branch.code === event.target.value);

        dispatch(setSelectedBranch(selectedBranch));
    }

    if (user === null) return null;

    const filteredBranches = user.on_demand_hub_id ? branches.filter(branch => branch.id === user.on_demand_hub_id) : branches;
    const finalFilteredBranches = filteredBranches.length === 0 ? branches : filteredBranches;

    if( finalFilteredBranches.length === 1 && selectedBranch.code !== finalFilteredBranches[0].code)
    {
        dispatch(setSelectedBranch(finalFilteredBranches[0]));
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="branch-select-label">Branch</InputLabel>
            <Select
                labelId="branch-select-label"
                value={selectedBranch.code}
                label="Branch"
                onChange={onChange}
            >
                {finalFilteredBranches.map((branch) => (
                    <MenuItem key={branch.code} value={branch.code}>
                        {branch.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default BranchSelector;