import React from "react";
import {
    Grid,
    Card,
    CardContent,
    CardActionArea,
    Typography,
    Icon, Box,
} from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import StarIcon from "@mui/icons-material/Star";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Home() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    if (!user) return "Loading";

    const options = [
        {
            title: "Apply For Shifts Next Week",
            icon: TodayIcon,
            onClick: () => navigate("/apply"),
            show: user.adhoc_shifting === false,
        },
        {
            title: "View This Week's Shifts",
            icon: EventAvailableIcon,
            onClick: () => navigate("/shifts"),
            show: user.adhoc_shifting === false,
        },
        {
            title: "Ad-hoc Shifting",
            icon: StarIcon,
            onClick: () => navigate("/adhoc"),
            show: user.adhoc_shifting === true,
        },
        {
            title: "Your Performance Last Week",
            icon: StarIcon,
            onClick: () => navigate("/performance"),
            show: true,
        },
        {
            title: "Your Bank and Address Details",
            icon: AccountBalanceIcon,
            onClick: () => navigate("/bank-details"),
            show: true,
        },
        {
            title: "Your Pro-Forma Invoice",
            icon: ReceiptIcon,
            onClick: () => navigate("/pro-forma-invoices"),
            show: true,
        },
    ];

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
                Welcome, {user.name}
            </Typography>
            <Grid container spacing={2}>
                {options.map(
                    (option, index) =>
                        option.show && (
                            <Grid item xs={12} sm={6} key={index}>
                                <Card>
                                    <CardActionArea onClick={option.onClick}>
                                        <CardContent sx={{ display: "flex", alignItems: "center" }}>
                                            <Icon
                                                component={option.icon}
                                                sx={{ fontSize: 40, mr: 2 }}
                                                color="primary"
                                            />
                                            <Typography variant="h6">{option.title}</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                )}
            </Grid>
        </Box>
    );
}

export default Home;
