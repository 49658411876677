import Echo from 'laravel-echo';
import {signal} from "@preact/signals-react";

export const pusherConnectedSignal = signal(false);

window.Pusher = require('pusher-js');

if (undefined === window.echoInstance)
    window.echoInstance = null;

export function authHeader() {
    const token = localStorage.getItem('dam_driver_token');

    if (token) {
        const tokenObject = JSON.parse(token);
        return `Bearer ${tokenObject.token}`;
    }
    return null;
}

/*
var pusher = new Pusher("app_key", {
  userAuthentication: {
    endpoint: "/pusher/user-auth",
    transport: "ajax",
    params: {},
    headers: {},
    customHandler: null,
  },
});
 */

const createEchoInstance = () => {
    //const urlObject = new URL(process.env.REACT_APP_API_BASE_URL);
    //const baseUrl = `${urlObject.hostname}`;

    if (!window.echoInstance) {
        const options = {
            authEndpoint: process.env.REACT_APP_API_BASE_URL + '/driver-api/auth/authenticate',
            broadcaster: 'pusher',
            key: process.env.REACT_APP_API_MIX_PUSHER_APP_KEY,
            wsHost: process.env.REACT_APP_API_PUSHER_URL, //baseUrl,
            wsPort: 6001,
            wssPort: parseInt(process.env.REACT_APP_API_PUSHER_PORT),
            encrypted: true,
            disableStats: true, // this disables stats for stats.pusher.com, we dont use this
            forceTLS: process.env.REACT_APP_API_PUSHER_FORCE_TLS === 'true',
            cluster: "bg1",
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Authorization: authHeader(),
                }
            }
        };


        //console.log('echo options', process.env.REACT_APP_API_PUSHER_FORCE_TLS, options);

        try {
            window.echoInstance = new Echo(options);

            pusherConnectedSignal.value = false;

            window.echoInstance.connector.pusher.connection.bind('state_change', states => {
                console.log('State changed from', states.previous, 'to', states.current);
                pusherConnectedSignal.value = states.current;
            });

            // Handle authentication errors (like 401)
            window.echoInstance.connector.pusher.connection.bind('error', error => {
                if (error.data && error.data.code === 401) {
                    console.error('Authentication failed: 401 Unauthorized');
                    // this only happens when you listen on a private channel, this is handled on a per case basis this global handler never gets used..
                    retryAuthentication();
                }
            });
            window.echoInstance.retryAuthentication = retryAuthentication;
        } catch (error) {
            console.error('echo error', error);
        }
    }
    return window.echoInstance;
};

const retryAuthentication = (retryCount = 0) => {
    const retryDelay = 2000; // 2 seconds delay before retry, up to 30 seconds

    pusherConnectedSignal.value = false;

    setTimeout(() => {
        console.log(`Retrying authentication attempt ${retryCount + 1}`);
        // Clear the previous instance to reset the state
        window.echoInstance = null;
        // Retry to get a new Echo instance
        createEchoInstance();
        retryAuthentication(retryCount + 1);
    }, Math.min(retryDelay * Math.min(30, retryCount)));
}

export const getEchoInstance = () => {
    return createEchoInstance();
};

