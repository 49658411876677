import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
//import reportWebVitals from './reportWebVitals';
import {SnackbarProvider} from "notistack";
import store from './store/store';
import './index.css';
import Root from "./Root";
import {createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    padding: '4px',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                
                @media (max-width: 1200px) {
                  .MuiTypography-root {
                    font-size: 2rem !important;
                  }
                  .MuiCheckbox-root {
                    padding: 3px !important;
                  }
                  .MuiButtonBase-root {
                    font-size: 2rem !important;
                  }
                  .MuiTableCell-root {
                    font-size: 2rem !important;
                  }
                  .MuiBox-root {
                    font-size: 2rem !important;
                  }
                  .MuiBadge-badge {
                    font-size: 2rem !important;
                  }
                  .MuiSvgIcon-root {
                    font-size: 2rem !important;
                  }          
                  .MuiBadge-root, .MuiBottomNavigationAction-label, .MuiChip-label {
                    font-size: 2rem !important;
                  }           
                  .MuiBottomNavigation-root {
                        height: 80px !important;
                        justify-content: space-evenly !important;
                   }       
                }

                @media (max-width: 800px) {
                  .MuiTypography-root {
                    font-size: 1.5rem !important;
                  }
                  .MuiCheckbox-root {
                    padding: 3px !important;
                  }
                  .MuiButtonBase-root {
                    font-size: 1.5rem !important;
                  }
                  .MuiTableCell-root {
                    font-size: 1.5rem !important;
                  }
                  .MuiBox-root {
                    font-size: 1.5rem !important;
                  }
                  .MuiBadge-badge {
                    font-size: 0.8rem !important;
                  }
                  .MuiSvgIcon-root {
                    font-size: 1.5rem !important;
                  }          
                  .MuiBadge-root, .MuiBottomNavigationAction-label, .MuiChip-label {
                    font-size: 1rem !important;
                  }           
                  .MuiBottomNavigation-root {
                        height: 70px !important;
                        justify-content: space-evenly !important;
                   }       
                }

                @media (max-width: 600px) {
                  .MuiTypography-root {
                    font-size: 1rem !important;
                  }
                  .MuiCheckbox-root {
                    padding: 3px !important;
                  }
                  .MuiButtonBase-root {
                    font-size: 1rem !important;
                  }
                  .MuiTableCell-root {
                    padding: 5 5 !important;
                    font-size: 1rem !important;
                  }
                  .MuiBox-root {
                    padding: 5 5 !important;
                    font-size: 1rem !important;
                  }
                  .MuiBadge-badge {
                    font-size: 0.7rem !important;
                  }
                  .MuiSvgIcon-root {
                      font-size: 1.5rem !important;
                   }
                  .MuiBadge-root, .MuiBottomNavigationAction-label, .MuiChip-label {
                    font-size: 1rem !important;
                  }                   
                  .MuiBottomNavigation-root {
                        height: 60px !important;
                        justify-content: space-evenly !important;
                   }                           
                }

                @media (max-width: 320px) {
                  .MuiTypography-root {
                    font-size: 0.8rem !important;
                  }
                  .MuiCheckbox-root {
                    padding: 2px !important;
                  }
                  .MuiButtonBase-root {
                    font-size: 0.8rem !important;
                  }
                  .MuiTableCell-root {
                    padding: 0 0 !important;
                    font-size: 0.8rem !important;
                  }
                  .MuiBox-root {
                    padding: 0 0 !important;
                    font-size: 0.8rem !important;
                  }
                  .MuiBadge-badge {
                    font-size: 0.6rem !important;
                  }
                  .MuiSvgIcon-root {
                      font-size: 1.2rem !important;
                   }
                  .MuiBadge-root, .MuiBottomNavigationAction-label {
                    font-size: 0.8rem !important;
                  }                   
                  .MuiBottomNavigation-root {
                        height: 56px !important;
                        justify-content: space-evenly !important;
                   }                           
                }
            `,
        },
    },
});


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={10}
                preventDuplicate={true}
                autoHideDuration={5000}
                style={{fontSize: '1.5em'}}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <React.StrictMode>
                    <Root/>
                </React.StrictMode>
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
