import axios from 'axios';
import store from "../store";
import { setLoading } from "../slices/navSlice";
import { logout } from "../slices/authSlice";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use((config) => {
    //const state = store.getState();
    //const token = state.auth.token;
    const token = localStorage.getItem('dam_driver_token');

    if (token) {
        const tokenObject = JSON.parse(token);
        config.headers.Authorization = `Bearer ${tokenObject.token}`;
    }

    store.dispatch(setLoading(true))

    //console.log("axios request", config);

    return config;
});

instance.interceptors.response.use(
    function (response) {
        store.dispatch(setLoading(false));
        if (response.status === 401) {
            console.log("axios response 401", response);
            store.dispatch(logout());
            return Promise.reject(response);
        }
        return response;
    },
    function (error) {
        store.dispatch(setLoading(false));
        if (error.response && error.response.status === 401) {
            console.log("axios error 401", error);
            store.dispatch(logout());
        }
        if (error.response && error.response.status === 422) {
            return Promise.resolve(error); // an email was sent, handle this as a success
        }
        return Promise.reject(error);
    }
);

export default instance;
