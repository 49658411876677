import React, {forwardRef, useImperativeHandle, useState} from 'react';
import { Chip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ComplianceStatus = forwardRef(({ status }, ref) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        openDialog: () => {
            if (status.compliance_state !== 'valid') {
                setDialogOpen(true);
            }
        }
    }));

    const statusAttributes = {
        valid: {
            label: 'Docs up to date',
            color: 'success',
            icon: <CheckCircleIcon />,
        },
        warning: {
            label: 'Docs Expiring Soon',
            color: 'warning',
            icon: <AccessTimeIcon />,
        },
        expiring: {
            label: 'Docs Expiring 7 Days',
            color: 'error',
            icon: <HighlightOffIcon />,
        },
        expired: {
            label: 'Docs Expired',
            color: 'error',
            icon: <HighlightOffIcon />,
        },
    };

    const { label, color, icon } = statusAttributes[status.compliance_state] || {};

    const handleClick = () => {
        if (status.compliance_state !== 'valid') {
            setDialogOpen(true);
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Chip
                sx={{
                    backgroundColor: "white",
                }}
                icon={icon}
                label={label}
                color={color}
                variant="outlined"
                onClick={handleClick}
            />

            {/* Compliance Details Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
            >
                <DialogTitle>Document Compliance Details</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="error" sx={{ marginBottom: 2 }}>
                        Warning: Once your documents expire, you will not be able to be shifted and all of your documents will need to be re-verified. This process can take up to 10 days, during which you will not be able to work.
                    </Typography>
                    <Typography variant="body2" color="warning" sx={{ marginBottom: 2 }}>
                        Please bring a copy of the expiring documents to the office as soon as possible. You will not be able to be shifted if you have less than a week left to become compliant until you do this.
                    </Typography>
                    {status.compliance_warnings && status.compliance_warnings.length > 0 ? (
                        <List>
                            {status.compliance_warnings.map((warning) => (
                                <ListItem key={warning.id} divider>
                                    <Typography variant="body1">
                                        <strong>Description:</strong> {warning.description}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Expire Date:</strong> {warning.expire_date}
                                    </Typography>
                                    <Typography variant="body1" color={ warning.state === 'valid' ? "" : "error" }>
                                        <strong>State:</strong> {warning.state}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography>No compliance warnings.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default ComplianceStatus;
