import {Box, Paper} from "@mui/material";

function CenteredPaperBox(props) {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh" p={2}>
            <Paper elevation={3} sx={{p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
                {props.children}
            </Paper>
        </Box>
    )
}

export default CenteredPaperBox;