import React, { Component } from 'react';
import PropTypes from "prop-types";
import axios from "../store/tokenAxios/tokenAxios";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Update state so the next render will show the fallback UI
        this.setState({ error, errorInfo });

        // Send error details to the server
        this.sendErrorToServer(error, errorInfo);
    }

    sendErrorToServer = (error, errorInfo) => {
        if (error === null && errorInfo === null) return;

        const { user } = this.props;
        axios.post('/driver-api/telegram/error', {
            driver_id: user?.id ?? null,
            error: JSON.stringify(error, Object.getOwnPropertyNames(error)), // Include non-enumerable properties
            error_info: JSON.stringify(errorInfo)
        })
            .then(response => {
                console.log('Error details sent to server', response);
            })
            .catch(err => {
                console.log('Failed to send error details to server', err);
            });
    }

    render() {
        if (this.state.hasError) {
            // Check if a custom fallback is provided
            if (this.props.fallback) {
                return typeof this.props.fallback === 'function'
                    ? this.props.fallback(this.state.error, this.state.errorInfo)
                    : this.props.fallback;
            }
            // Default fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    user: PropTypes.object // Add the user prop type validation
};

export default ErrorBoundary;
