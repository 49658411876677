import { createSlice } from '@reduxjs/toolkit';
import {signal} from "@preact/signals-react";

const getParsedToken = (token) => {
    if (typeof token !== 'string') {
        return token;
    }
    try {
        return JSON.parse(token);
    } catch (error) {
        console.error("Error parsing token: ", error);
        return null;
    }
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        user: null,
        branches: [],
        error: null,
        chatContactId: null
    },
    reducers: {
        setUser(state, action) {
            const parsedToken = getParsedToken(action.payload);
            state.user = action.payload.user;
            state.branches = action.payload.branches;
            state.token = parsedToken.token; // action.payload.token;
            state.chatContactId = parsedToken.chatContactId??null;
            if( parsedToken?.token ) {
                loggedInTokenSignal.value = parsedToken.token;
                localStorage.setItem('dam_driver_token', JSON.stringify(action.payload));
            } else {
                loggedInTokenSignal.value = null;
                localStorage.removeItem('dam_driver_token');
            }
        },
        logout(state, action) {
            state.user = null;
            state.branches = [];
            state.token = null;
            loggedInTokenSignal.value = null;
            localStorage.removeItem('dam_driver_token');
        }
    },
});

export const selectChatContactId = (state) => state.auth.chatContactId;
export const { setUser, logout} = authSlice.actions
export default authSlice.reducer;
export const loggedInTokenSignal = signal(null);
